import styled from 'styled-components';
import { SITE_WIDTH } from '../../constants/config';
import { COLORS } from '../../constants/colors';

export const Container = styled.div<{ centered?: boolean }>`
  max-width: ${SITE_WIDTH};
  margin: 0 auto;
  padding-top: 90px;
  min-height: calc(100vh - 170px);
  padding-bottom: 20px;
  display: flex;
  ${(p) => p.centered && `justify-content: center; align-items: center;`}
  & > div {
    width: 100%;
  }
`;

export const Footer = styled.footer`
  height: 60px;
  background-color: ${COLORS.navigationColor};
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 40px;
  & > a {
    text-decoration: none;
    color: ${COLORS.textColor};
  }
`;
