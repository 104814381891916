import Cookies from 'universal-cookie';
import { ProductType } from '../../types';

const cookies = new Cookies();

export const cart = (
  state: ProductType[] = [],
  action: { type?: string; product?: ProductType; qty?: number; products?: ProductType[] } = {},
) => {
  switch (action.type) {
    case 'updateCart': {
      if (!action.product) return state;

      let productInCart = state.find((item) => item.code === action.product?.code);

      if (productInCart && productInCart.orderQty) {
        productInCart = {
          ...productInCart,
          orderQty: action.qty || 0,
        };

        state = state
          .map((item) => (productInCart && item.code === productInCart.code ? productInCart : item))
          .filter((item) => item.orderQty && item.orderQty > 0);
      } else {
        state.push({ ...action.product, orderQty: action.qty || action.product.minOrderQty });
      }

      const items = state.map((item) => {
        return { ...item, orderQty: item.orderQty && item.orderQty <= item.qty ? item.orderQty : item.qty };
      });

      cookies.set('cart', items ? JSON.stringify(items) : '', { path: '/', maxAge: 259200 });

      return items;
    }
    case 'restoreCart': {
      return action.products || [];
    }
    case 'clearCart': {
      cookies.set('cart', '', { path: '/', maxAge: 259200 });
      return [];
    }
    default:
      return state;
  }
};
