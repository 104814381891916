import { ProductType } from '../../types';

export const products = (state: ProductType[] = [], action: { type?: string; products?: ProductType[] } = {}) => {
  switch (action.type) {
    case 'setProducts':
      return action.products;
    default:
      return state;
  }
};
