import { transliterateUrl } from '.';
import { CategoryType, ProductType } from '../../types';
import { PRODUCTS_CSV_FILE_PATH } from '../../constants/config';

export const fetchCsv = async (csvFilePath: string = PRODUCTS_CSV_FILE_PATH) => {
  const response = await fetch(csvFilePath);

  if (response && response.body) {
    const res = response.text();
    const csv = (await res).split('\n');

    const categories: CategoryType[] = [];

    const product = (url: string, r: string[]) => {
      const price = r[5] ? parseFloat(parseFloat(r[5].replace(',', '.')).toFixed(2)) : 0;

      const item: ProductType = {
        categoryUrl: url,
        type: r[0],
        code: r[1],
        name: r[2].replace(/"/g, ' '),
        unit: r[3],
        qty: parseFloat(r[4].replace(',', '.')),
        price: price,
        minOrderQty: r[6] ? parseFloat(r[6].replace(',', '.')) : 1,
        orderQty: 0,
      };

      return item;
    };

    const category = (link: string) => {
      let url = link;

      const cat = categories.find((cat) => cat.link === link);
      if (!cat) {
        url = transliterateUrl(link);
        categories.push({ url: url, link: link });
      } else {
        url = cat.url;
      }

      return url;
    };
    const products: ProductType[] = [];

    csv.forEach((line) => {
      const r = line.split(';');
      if (r[0] && r[1] && r[2] && r[3] && parseInt(r[4]) > 0 && parseInt(r[5]) > 0 && r[6]) {
        const link = r[0].split('/')[0];
        const url = category(link);
        const item = product(url, r);
        if (item.price > 0 && item.qty > 0) {
          products.push(item);
        }
      }
    });

    return {
      products: products.sort((item1, item2) => {
        return item1.name.localeCompare(item2.name);
      }),
      categories: categories.sort((item1, item2) => {
        return item1.link.localeCompare(item2.link);
      }),
    };
  }
};
