import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';

export const Content = () => {
  const [content, setContent] = useState('');
  const { url } = useParams();

  useEffect(() => {
    fetch(`/pages/${url}.html`)
      .then((response) => response.text())
      .then((data) => {
        setContent(data);
      });
  }, [url]);

  return (
    <Container fluid="md" className="pt-4">
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </Container>
  );
};
