import { LinkContainer } from 'react-router-bootstrap';
import { Container, Row, Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/';

export const Homepage = () => {
  const categories = useSelector((state: RootState) => state.categories);

  return (
    <Container className="mt-4">
      <Row style={{ textAlign: 'center', justifyContent: 'center' }}>
        {categories &&
          categories.length > 0 &&
          categories.map((cat) => {
            return (
              <Card key={cat.url} style={{ width: '20rem' }} className="m-3">
                <Card.Img
                  variant="top"
                  src={`/category/${cat.url}.jpg`}
                  style={{ height: 100, objectFit: 'contain', marginTop: 15 }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src =
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                  }}
                />
                <Card.Body>
                  <Card.Title>{cat.link}</Card.Title>
                  <LinkContainer to={`/store/${cat.url}`}>
                    <Button variant="primary">Налични продукти &raquo;</Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            );
          })}
      </Row>
    </Container>
  );
};
