import { ProductType, CategoryType } from '../../types';

export const setCategories = (categories: CategoryType[]) => {
  return {
    type: 'setCategories',
    categories: categories,
  };
};

export const setProducts = (products: ProductType[]) => {
  return {
    type: 'setProducts',
    products: products,
  };
};

export const addToCart = (product: ProductType) => {
  return {
    type: 'addToCart',
    product: product,
  };
};

export const updateCart = (product: ProductType, qty?: number) => {
  return {
    type: 'updateCart',
    product: product,
    qty: qty,
  };
};

export const restoreCart = (products: ProductType[]) => {
  return {
    type: 'restoreCart',
    products: products,
  };
};

export const clearCart = () => {
  return {
    type: 'clearCart',
  };
};
