import { FC } from 'react';
import { Container, Footer } from './Page.style';
import { Navigation } from '../';
import { COPYRIGHT, COPYRIGHT_LINK } from '../../constants/config';

import GlobalStyle from '../styled/Global.style';
export const Page: FC = ({ children }) => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: '94vh' }}>
      <GlobalStyle />
      <Navigation />
      <Container style={{ minHeight: '94vh' }}>
        <div>{children}</div>
      </Container>
      <Footer style={{ height: '6vh' }}>
        <a href={COPYRIGHT_LINK} target="_blank" rel="noreferrer">
          {COPYRIGHT}
        </a>
      </Footer>
    </div>
  );
};
