import { combineReducers } from 'redux';
import { categories, products, cart } from './';

export const rootReducer = combineReducers({
  categories: categories,
  products: products,
  cart: cart,
});

export type RootState = ReturnType<typeof rootReducer>;
