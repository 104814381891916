import { useEffect, useState } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/';
import { ShoppingCart } from '../';
import { COMPANY_NAME } from '../../constants/config';
import { NavigationLink } from '../../types';

export const Navigation = () => {
  const [pages, setPages] = useState<NavigationLink[]>([]);
  const categories = useSelector((state: RootState) => state.categories);

  useEffect(() => {
    fetch('/header.json')
      .then((response) => response.json())
      .then((json) => {
        setPages(json);
      });
  }, []);
  return (
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" fixed="top">
      <Container>
        <LinkContainer to={'/'}>
          <Navbar.Brand>{COMPANY_NAME}</Navbar.Brand>
        </LinkContainer>
        <Navbar>
          <ShoppingCart />
        </Navbar>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Item></Nav.Item>
          </Nav>
          <Nav>
            {pages.map((item) =>
              item.url.indexOf('://') > 0 ? (
                <Nav.Link href={item.url}>{item.title}</Nav.Link>
              ) : (
                <LinkContainer key={item.url} to={`${item.url}`}>
                  <Nav.Link>{item.title}</Nav.Link>
                </LinkContainer>
              ),
            )}

            <NavDropdown title="Магазин" id="collasible-nav-dropdown">
              {categories?.map((item) => (
                <LinkContainer key={item.url} to={`/store/${item.url}`}>
                  <NavDropdown.Item>{item.link}</NavDropdown.Item>
                </LinkContainer>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
