export const COLORS = {
  backgroundColor: '#FFFFFF',
  navigationColor: '#F9F9FB',
  buttonText: '#ffffff',
  primaryColor: '#ca2c2c',
  primaryColorText: '#FFFFFF',
  buttonColor: '#B0A8B9',
  secondColor: '#28839F',
  textColor: '#454545',
  textColorLight: '#646464',
  secondTextColor: '#ffffff',
  titleColor: '#454545',
  subtitleColor: '#454545',
  boxBackgroundColor: '#ffffff',
  boxBackgroundColorHover: '#f9f9f9',
  boxBorderColor: '#D5D5D5',
  iconsColor: '#ffffff',
  errorRed: '#F46165',
  formBorderActiveColor: '#6E7D85',
  successGreen: '#38A169',
  calendarAvailable: '#38A16970',
  calendarSelected: '#38A169',
  calendarUnavailable: '#cccccc',
  calendarBlank: '#ffffff',
  calendarBorder: '#343434',
  backgroundColorLight: '#C6F6D5',
  navigationLinkColor: '#ffffff',
  linkColor: '#8A99A6',
};
