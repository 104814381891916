import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import * as Screen from './screens';
import { Page, fetchCsv } from './components';
import { setCategories, setProducts, restoreCart, updateCart } from './redux/actions';
import { ProductType } from './types';

const App = () => {
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    fetchCsv().then((item) => {
      item && item.products && dispatch(setProducts(item.products));
      item && item.categories && dispatch(setCategories(item.categories));

      const cookies = new Cookies();

      const cartCookie = cookies.get('cart');

      if (cartCookie) {
        const cartItems = cartCookie.filter((cartItem: ProductType) => {
          const existingProduct = item?.products.find((p) => p.code === cartItem.code);
          if (!existingProduct) return false;

          return existingProduct.qty >= cartItem.orderQty;
        });

        dispatch(restoreCart(cartItems));
      }
    });
  }, []);

  return (
    <Page>
      <Routes>
        <Route path="/" element={<Screen.Homepage />} />
        <Route path="/store" element={<Screen.Store />} />
        <Route path="/store/:selectedCategory" element={<Screen.Store />} />
        <Route path="/:url" element={<Screen.Content />} />
      </Routes>
    </Page>
  );
};

export default App;
