import { useEffect, useState } from 'react';
import { Modal, Button, Badge, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/';
import { formatPrice } from '../utils';
import { OrderQty, Checkout } from '../';

export const ShoppingCart = () => {
  const cart = useSelector((state: RootState) => state.cart);
  const [orderTotal, setOrderTotal] = useState(0);

  const [showCart, setShowCart] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);

  useEffect(() => {
    const total = cart.length ? cart.map((item) => item.orderQty * item.price).reduce((a, b) => a + b) : 0;

    setOrderTotal(total);
  }, [cart]);

  const handleClose = () => {
    setShowCart(false);
    setShowCheckout(false);
  };
  const handleShow = () => {
    setShowCheckout(false);
    setShowCart(true);
  };

  const AvailableProducts = () => {
    return (
      <tbody>
        {cart.map((item) => {
          return (
            <tr key={item.code}>
              <td>
                {item.name}
                <br />
                <small>
                  {formatPrice(item.price)} за {item.minOrderQty} {item.unit}
                </small>
              </td>

              <td align="center">
                <OrderQty item={item} />
              </td>
              <td>{formatPrice(item.price * item.orderQty)}</td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <>
      {orderTotal > 0 && (
        <Button variant="primary" onClick={handleShow}>
          Поръчка &raquo;{' '}
          <Badge pill bg="dark">
            {formatPrice(orderTotal)}
          </Badge>
        </Button>
      )}

      <Modal show={showCart} onHide={handleClose} backdrop={'static'}>
        <Modal.Header closeButton>
          <Modal.Title>Поръчка</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderTotal > 0 ? (
            <Table striped bordered hover responsive size="sm">
              <thead>
                <tr>
                  <th>Продукт</th>
                  <th>Количество</th>
                  <th>Цена</th>
                </tr>
              </thead>
              <AvailableProducts />
            </Table>
          ) : (
            'Няма добавени продукти.'
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Затвори
          </Button>
          {cart.length && (
            <Button
              variant="primary"
              onClick={() => {
                setShowCheckout(true);
                setShowCart(false);
              }}
            >
              Завърши поръчката &raquo;
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Checkout showCheckout={showCheckout} handleClose={handleClose} handleShow={handleShow} orderTotal={orderTotal} />
    </>
  );
};
