import { FC, useEffect, useState } from 'react';
import { Modal, Button, Form, FloatingLabel, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import Cookies from 'universal-cookie';
import { CheckoutStateType } from '../../types';
import { clearCart } from '../../redux/actions';
import { RootState } from '../../redux/';
import { submitOrder } from '../';
import { FREE_SHIPPING_MINIMUM_ORDER_AMOUNT } from '../../constants/config';
import { formatPrice } from '../utils';

export const Checkout: FC<{
  showCheckout: boolean;
  handleClose: () => void;
  handleShow: () => void;
  orderTotal: number;
}> = ({ showCheckout, handleClose, handleShow, orderTotal }) => {
  const [submitOrderError, setSubmitOrderError] = useState('');
  const [sentOrderMessage, setSentOrderMessage] = useState('');
  const [validated, setValidated] = useState(false);
  const [orderSent, setOrderSent] = useState(false);
  const [orderSending, setOrderSending] = useState(false);
  const [formValues, setFormValues] = useState<CheckoutStateType>({});
  const cart = useSelector((state: RootState) => state.cart);
  const dispatch: Dispatch = useDispatch();
  const cookies = new Cookies();

  useEffect(() => {
    const savedFormData = cookies.get('checkoutDetails');
    if (savedFormData) {
      setFormValues(savedFormData as CheckoutStateType);
    }
  }, []);

  useEffect(() => {
    setOrderSent(false);
  }, [showCheckout]);

  const handleSubmit = async (event: {
    currentTarget: {checkValidity: () => boolean;};
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    cookies.set('checkoutDetails', JSON.stringify(formValues), { path: '/', maxAge: 25920000 });

    if (form.checkValidity() === true) {
      setOrderSending(true);

      const orderStatus: { successMessage?: string; errorMessage?: string } = await submitOrder({
        ...formValues,
        orderItems: cart,
      });

      if (orderStatus.errorMessage) {
        setSubmitOrderError(orderStatus.errorMessage);
      } else if (orderStatus.successMessage) {
        setSentOrderMessage(orderStatus.successMessage);
        dispatch(clearCart());
        setOrderSent(true);
      }
      setOrderSending(false);
    }
    setValidated(true);
  };

  return (
    <Modal show={showCheckout} onHide={handleClose} backdrop={'static'}>
      <Modal.Header closeButton>
        <Modal.Title>Поръчка</Modal.Title>
      </Modal.Header>
      {orderSent ? (
        <Modal.Body>{sentOrderMessage}</Modal.Body>
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            {submitOrderError && (
              <Alert variant="warning" className="mb-3">
                {submitOrderError}
              </Alert>
            )}
            <p>
              Цена на продукти: <b>{String(formatPrice(orderTotal))}</b>
            </p>
            <p className="mt-3">
              Цена за доставка:{' '}
              <b>
                {orderTotal >= FREE_SHIPPING_MINIMUM_ORDER_AMOUNT
                  ? 'безплатна доставка'
                  : 'за сметка на получателя, по тарифата на куриера'}
              </b>
            </p>
            <Row>
              <Col>
                <FloatingLabel controlId="floatingName" label="Име" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Име"
                    required
                    onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                    defaultValue={formValues.name}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingLastname" label="Фамилия">
                  <Form.Control
                    type="text"
                    placeholder="Фамилия"
                    required
                    onChange={(e) => setFormValues({ ...formValues, lastName: e.target.value })}
                    defaultValue={formValues.lastName}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel controlId="floatingPhone" label="Телефон" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Телефон"
                    required
                    onChange={(e) => setFormValues({ ...formValues, phone: e.target.value })}
                    defaultValue={formValues.phone}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingEmail" label="Email адрес">
                  <Form.Control
                    type="email"
                    placeholder="Email адрес"
                    required
                    onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                    defaultValue={formValues.email}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Form.Label htmlFor="delveryAddress">Адрес за доставка</Form.Label>
            <Form.Control
              as="textarea"
              placeholder=""
              rows={3}
              required
              onChange={(e) => setFormValues({ ...formValues, deliveryAddress: e.target.value })}
              defaultValue={formValues.deliveryAddress}
            />

            <Form.Label htmlFor="invoiceDetails">Данни за фактура</Form.Label>
            <Form.Control
              as="textarea"
              placeholder=""
              rows={3}
              onChange={(e) => setFormValues({ ...formValues, invoiceDetails: e.target.value })}
              defaultValue={formValues.invoiceDetails}
            />
          </Modal.Body>
          <Modal.Footer>
            {orderSending ? (
              <Button variant="primary" disabled>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Изпращане...
              </Button>
            ) : (
              <>
                <Button variant="secondary" onClick={handleShow}>
                  &laquo; Назад
                </Button>
                <Button type="submit" variant="primary">
                  Изпрати поръчката &raquo;
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};
