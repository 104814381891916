import { CategoryType } from '../../types';

export const categories = (state: CategoryType[] = [], action: { type?: string; categories?: CategoryType[] } = {}) => {
  switch (action.type) {
    case 'setCategories':
      return action.categories;

    default:
      return state;
  }
};
