import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    min-height:100vh;
    position:relative;
}

`;

export default GlobalStyle;
