import emailjs from '@emailjs/browser';
import { formatPrice } from '../';
import { CheckoutStateType } from '../../types';
import { EMAILJS_SERVICEID, EMAILJS_TEMPLATEID, EMAILJS_USERID } from '../../constants/credentials';

export const submitOrder = async (checkoutData: CheckoutStateType) => {
  const orderItems = checkoutData.orderItems
    ?.map(
      (item) =>
        `<tr><td>${item.code}</td><td>${item.name}</td><td>${item.price} лв/${item.minOrderQty} ${item.unit}</td><td>${
          item.orderQty
        } ${item.unit}</td><td>${formatPrice(item.orderQty * item.price)} лв</td></tr>`,
    )
    .join('\n');

  const orderTotal = checkoutData.orderItems?.map((item) => item.orderQty * item.price).reduce((a, b) => a + b);

  const templateParams = {
    customerEmail: checkoutData.email,
    customerPhone: checkoutData.phone,
    customerName: `${checkoutData.name} ${checkoutData.lastName}`,
    reply_to: 'info@brackets.studio',
    deliveryAddress:
      checkoutData.deliveryAddress && checkoutData.deliveryAddress.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2'),
    invoiceDetails: checkoutData.invoiceDetails
      ? checkoutData.invoiceDetails.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')
      : 'Не желае фактура,',
    orderItems: `<table border="1"><tr><td><b>Стока</b></td><td><b>Код</b></td><td><b>Цена</b></td><td><b>Поръчани</b></td><td><b>Цена общо</b></td></tr>${orderItems}</table>`,
    orderTotal: orderTotal && formatPrice(orderTotal),
  };

  return emailjs.send(EMAILJS_SERVICEID, EMAILJS_TEMPLATEID, templateParams, EMAILJS_USERID).then(
    (result) => {
      console.log(result.text);
      return { successMessage: 'Вашата поръчка беше изпратена!' };
    },
    (error) => {
      console.log(error.text);
      return { errorMessage: error.text };
    },
  );
};
