import { FC, useMemo } from 'react';
import { Stack, Button } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { updateCart } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/';
import { ProductType } from '../../types';

export const OrderQty: FC<{ item: ProductType }> = ({ item }) => {
  const cart = useSelector((state: RootState) => state.cart);
  const dispatch: Dispatch = useDispatch();

  const inCart = useMemo(() => cart.find((cartItem) => item.code === cartItem.code), []);

  return (
    <>
      {' '}
      {inCart ? (
        <Stack direction="horizontal" gap={2} style={{ justifyContent: 'center' }}>
          <Button
            variant={'primary'}
            size="sm"
            onClick={() => {
              dispatch(updateCart(item, inCart.orderQty - inCart.minOrderQty));
            }}
          >
            -
          </Button>
          {inCart.orderQty} {inCart.unit}
          <Button
            variant={'primary'}
            size="sm"
            disabled={inCart.orderQty + inCart.minOrderQty > inCart.qty}
            onClick={() => {
              dispatch(updateCart(item, inCart.orderQty + inCart.minOrderQty));
            }}
          >
            +
          </Button>
        </Stack>
      ) : (
        <Button
          variant={inCart ? 'secondary' : 'primary'}
          size="sm"
          onClick={() => {
            dispatch(updateCart(item, 1));
          }}
        >
          Поръчай
        </Button>
      )}
    </>
  );
};
