import { FC, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/';
import { ProductType } from '../../types';
import { formatPrice, OrderQty } from '../../components';

export const Store: FC = () => {
  const { selectedCategory } = useParams();
  const products = useSelector((state: RootState) => state.products);
  const categories = useSelector((state: RootState) => state.categories);
  const cart = useSelector((state: RootState) => state.cart);
  const [filteredProducts, setFilteredProducts] = useState<ProductType[]>([]);

  useEffect(() => {
    if (products && products.length > 0) {
      const p: ProductType[] = products?.filter((item) => item.categoryUrl === selectedCategory && item.qty > 0);
      setFilteredProducts(p);
      window.scrollTo(0, 0);
    }
  }, [selectedCategory, products]);

  const AvailableProducts = useCallback(() => {
    return (
      <tbody>
        {filteredProducts.map((item) => {
          return (
            <tr key={item.code}>
              <td valign="middle" align="center">
                <OrderQty item={item} />
              </td>
              <td>
                {item.name}
                <br />
                <small>
                  <b>код:</b> {item.code} <b>наличност:</b> {item.qty} {item.unit}
                </small>
              </td>
              <td valign="middle">
                <small>
                  <b>{formatPrice(item.price)}</b> за {item.minOrderQty} {item.unit}
                </small>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }, [filteredProducts, cart]);

  return (
    <>
      <Nav variant="pills" activeKey={selectedCategory}>
        {categories &&
          categories.length > 0 &&
          categories.map((cat) => {
            return (
              <Nav.Item key={cat.url}>
                <LinkContainer to={`/store/${cat.url}`}>
                  <Nav.Link eventKey={cat.url}>{cat.link}</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            );
          })}
      </Nav>

      {selectedCategory && (
        <>
          <h2 className="mt-3">{categories?.find((cat) => cat.url === selectedCategory)?.link}</h2>
          <Table striped bordered hover responsive="xl">
            <thead>
              <tr>
                <th>Поръчка</th>
                <th>Продукт</th>
                <th>Цена</th>
              </tr>
            </thead>

            <AvailableProducts />
          </Table>
        </>
      )}
    </>
  );
};
